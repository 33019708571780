<script>
import {indicadorEstoque} from "@/components/defaults/indicadorestoque";
// import Multiselect from "vue-multiselect";

export default {
  components:{
    // Multiselect
  },
  props: {
      currentEmpresa: { type: Object },
      oldLocalEstoque: { type: Object },
    },
  data() {
    return {
      
      localEstoque: {
        id: -1,
        descricao: null,
        status: true,
        empresa_id: null,
        ind_est: 0
      },
      listIndicadores: [],
      loader: {
        get: false
      },
      orientacoes: [
        {
          id: 1,
          nome: "Entrdas e Saídas",
        },
        {
          id: 2,
          nome: "Só entradas",
        },
        {
          id: 3,
          nome: "Só Saídas",
        },
      ],
      tipos: [
        {
          id: 1,
          nome: "Analitica",
        },
        {
          id: 2,
          nome: "Sintetica",
        },
      ],
      select_tipo: null,
      select_orientacao: null,
      planos_contas:[
        {
          id:1,
          nome:"plano 01"
        },
        {
          id:2,
          nome:"plano 02"
        },
      ],
      show_multiselect: false,
      select_plano_conta: null,
      convenio:{
        
      }
    }
  },
  created(){
    this.convenio = this.oldLocalEstoque
  },
  mounted() {
    this.$emit('newTitle', 'Alterar Convenio');
    this.localEstoque = this.oldLocalEstoque;
    this.listIndicadores = indicadorEstoque;
  },
  methods: {
    
    setPut() {
      
      this.$emit('doPut', this.localEstoque);
    },
    abr_multiselect(valor) {
      console.log("entrar");
      if(valor == 1){
        this.show_multiselect = true
      }else {
        this.show_multiselect = false
      }
    },
    selecionar_plano(event){
      this.plano.woner_id = event.id
    },
    selecionar_tipos(event){
      this.plano.classificacao = event.id
    },
    seleciona_orientacoes(event){
      this.plano.natureza = event.id
    }
  },
}
</script>

<template>
<div class="card">
    <div class="card-body">
      <div class="row">
        <!-- <div class="col-md-6">
          <label for="">{{ "*Código" }}</label>
          <input v-model="convenio.codigo_reduzido" type="text" class="form-control text-left" />
        </div> -->
        <div class="col-md-12">
          <label for="">{{ "*Nome" }}</label>
          <input v-model="convenio.name" type="text" class="form-control text-left" />
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click.prevent="setPut()">
          Alterar
        </button>
        
      </div>
    </div>
  </div>
</template>